import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import { COLOR_THEME } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import theme, { COLOR } from 'src/styles/theme'
import Tag from 'src/common/components/tag/Tag'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { backgroundColor } = props
  const dark = backgroundColor === COLOR_THEME.CYPRESS_GREEN

  return {
    wrapper: {
      backgroundColor: dark
        ? theme.palette.primary.dark
        : COLOR.LIGHT_BACKGROUND,
      padding: `${rem('40')} ${rem('24')}`,
      [theme.breakpoints.up('md')]: {
        padding: rem('40'),
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${rem('64')} ${rem('40')}`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem('64')} ${rem('156')}`,
      },
    },
    titleText: {
      ...theme.typography.h2,
      fontSize: rem('48'),
      lineHeight: rem('44'),
      marginBottom: rem('16'),
      color: dark ? COLOR.NEAR_WHITE : theme.palette.primary.dark,
      [theme.breakpoints.up('md')]: {
        fontWeight: '500',
      },
    },
    tagsBox: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: rem('8'),
    },
  }
})

function Tags(props) {
  const { data } = props

  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { tags, section_id } = data
  const {
    tag_items = [],
    background_color: backgroundColor,
    variant,
  } = tags || {}

  if (!tag_items.length) return null

  const { classes } = useStyles({ backgroundColor })

  return (
    <Box
      className={`module ${classes.wrapper}`}
      id={section_id}
      data-id="section"
    >
      <Typography component="h2" className={classes.titleText}>
        TAGS
      </Typography>
      <Box className={classes.tagsBox}>
        {tag_items.map(
          (tag) =>
            tag.name && (
              <Tag
                title={tag.name}
                link={tag.url}
                variant={variant || 'primary'}
                key={tag.term_id}
              />
            )
        )}
      </Box>
    </Box>
  )
}

export default Tags

const tagPropTypes = {
  name: PropTypes.string,
  term_id: PropTypes.number,
  url: PropTypes.string,
}

Tags.propTypes = {
  data: PropTypes.shape({
    section_id: PropTypes.string,
    tags: PropTypes.shape({
      tag_items: PropTypes.arrayOf(PropTypes.shape(tagPropTypes)),
      background_color: PropTypes.string,
      variant: PropTypes.string,
    }),
  }),
}
