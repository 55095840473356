import { Box, Chip } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import { rem } from 'src/common/utils/css'
import theme, { COLOR, SECONDARY_COLOR, SHADOW } from 'src/styles/theme'

const VARIANT_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
}

const useStyles = makeStyles()((defaultTheme, props) => {
  const { variant } = props

  let tag
  switch (variant) {
    case VARIANT_TYPE.SECONDARY:
      tag = {
        backgroundColor: COLOR.BAKER_BEACH_WHITE,
        '&: hover': {
          backgroundColor: COLOR.BAKER_BEACH_GRAY,
        },
        '&: active': {
          boxShadow: 'unset',
          backgroundColor: COLOR.LIGHT_GRAY,
        },
      }
      break
    case VARIANT_TYPE.TERTIARY:
      tag = {
        backgroundColor: COLOR.LIGHT_BACKGROUND,
        '&: hover': {
          backgroundColor: COLOR.BAKER_BEACH_WHITE,
        },
        '&: active': {
          boxShadow: 'unset',
          backgroundColor: COLOR.BAKER_BEACH_GRAY,
        },
      }
      break
    default:
      tag = {
        backgroundColor: SECONDARY_COLOR.LIGHT[40],
        '&: hover': {
          backgroundColor: SECONDARY_COLOR.LIGHT[60],
        },
        '&: active': {
          backgroundColor: SECONDARY_COLOR.LIGHT[80],
          boxShadow: 'unset',
        },
      }
  }

  return {
    tagItem: {
      ...tag,
      width: 'max-content',
      borderRadius: rem(16),
      '&: hover': {
        ...tag['&: hover'],
        boxShadow: SHADOW.LEVEL_TWO,
      },
    },
    label: {
      ...theme.typography.body.default,
      backgroundColor: 'transparent',
      padding: `${rem(7.5)} ${rem(12)} ${rem(8.5)}`,
      lineHeight: rem(16),
      letterSpacing: '0',
      color: COLOR.DARK_GRAY,
    },
  }
})

function Tag(props) {
  const { title, link, variant } = props
  const { classes } = useStyles({ variant })

  if (!title) return null

  return (
    <Box component="a" href={link || '#'} tabIndex={0} data-ga-location="tag">
      <Chip
        label={title.replace('&amp;', '&')}
        className={classes.tagItem}
        classes={{
          label: classes.label,
        }}
        aria-label={title}
        clickable
        disableRipple
        tabIndex={-1}
      />
    </Box>
  )
}

export default Tag

Tag.propTypes = {
  title: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
  }),
  variant: PropTypes.string,
}
